import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/aviso-de-privacidad",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};

export const AvisoDePrivacidad = () => {
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Aviso de privacidad | Vivus Mx</title>
        <meta name="title" content="Aviso de privacidad | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <div className="flex flex-col gap-4 py-4">
            {/* <DemoButton callback={fillDemo} /> */}
            <div className="w-full flex flex-col gap-2">
              <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Aviso de privacidad</h1>
              <h2 className="text-xl lg:text-2xl text-green-light">Aviso de Privacidad para Clientes de Difinance, S.A. de C.V., SOFOM, E.N.R.</h2>
            </div>
            <div>
              <h2 className="font-bold text-2xl mt-2">1. Identidad y Domicilio del “Responsable” del tratamiento de sus datos personales.</h2>
              <p className="pb-4">
                <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong>, con domicilio para oír y recibir toda clase de notificaciones, el ubicado en Calle Ceres no. 23, Col. Crédito Constructor, Código Postal 03940, Alcaldía Benito Juárez, en la Ciudad de México, en su carácter de
                “Responsable” hace de su conocimiento que los datos personales, datos patrimoniales o financieros y datos personales sensibles que nos proporcione (en adelante sus “Datos”), serán protegidos conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de
                los Particulares, su Reglamento, lineamientos, parámetros y demás disposiciones aplicables (en adelante la “Ley”).
              </p>
              <h2 className="font-bold text-2xl mt-2">
                2. Datos personales que recaba el <i>“Responsable”</i>.
              </h2>
              <p className="pb-4">
                Los datos personales, incluidos los sensibles que <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong>, solicita al usuario (en lo sucesivo “Cliente” y/o “Acreditado”) para llevar a cabo las finalidades descritas en el presente aviso de privacidad son los que a continuación se
                mencionan:
              </p>
              <div style={{ display: "flex", flexDirection: "column", margin: "1rem 0" }}>
                <div style={{ display: "flex", padding: "1rem", backgroundColor: "#006d38", color: "white", fontWeight: "bold" }}>
                  <div style={{ textTransform: "uppercase", width: "33%" }}>Tipo de datos</div>
                  <div style={{ textTransform: "uppercase", width: "67%" }}>Datos personales</div>
                </div>
                <div style={{ display: "flex", backgroundColor: "white" }} className="privacy-row">
                  <div style={{ width: "33%", padding: "1rem", border: "1px solid #006d38", fontWeight: "bold", display: "flex", alignItems: "center", textTransform: "uppercase" }}>IDENTIFICACIÓN</div>
                  <div style={{ width: "67%", padding: "1rem", border: "1px solid #006d38" }}>
                    <p className="pb-4">
                      Nombre completo, estado civil, fecha de nacimiento, país de nacimiento, fecha de nacimiento, ocupación, profesión, Registro Federal de Contribuyentes, Clave Única de Registro de Población, nacionalidad, lugar donde trabaja y/o ha trabajado, Número del Seguro Social, sexo,
                      documentos migratorios de ser aplicables y edad.
                    </p>
                    <p className="pb-4">
                      ** Al proporcionar datos personales relacionados con sus familiares y referencias, usted reconoce tener el consentimiento de éstos para que el “Responsable” trate sus datos para los fines que señala este Aviso y demás necesarios para el proceso de contratación.
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", backgroundColor: "white" }} className="privacy-row">
                  <div style={{ width: "33%", padding: "1rem", border: "1px solid #006d38", fontWeight: "bold", display: "flex", alignItems: "center", textTransform: "uppercase" }}>De contacto</div>
                  <div style={{ width: "67%", padding: "1rem", border: "1px solid #006d38" }}>
                    <p className="pb-4">Domicilio, correo electrónico, teléfono fijo, teléfono de trabajo y teléfono celular.</p>
                  </div>
                </div>
                <div style={{ display: "flex", backgroundColor: "white" }} className="privacy-row">
                  <div style={{ width: "33%", padding: "1rem", border: "1px solid #006d38", fontWeight: "bold", display: "flex", alignItems: "center", textTransform: "uppercase" }}>Patrimoniales o financieros</div>
                  <div style={{ width: "67%", padding: "1rem", border: "1px solid #006d38" }}>
                    <p className="pb-4">
                      Origen de los recursos, monto de sus ingresos, monto de su salario en caso de ser aplicable, número de semanas cotizadas en el Instituto Mexicano del Seguro Social en caso de ser aplicable, historial laboral, historial crediticio, información sobre dependientes económicos,
                      ingresos, clave bancaria, estado de cuenta en caso de ser aplicable.
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", backgroundColor: "white" }} className="privacy-row">
                  <div style={{ width: "33%", padding: "1rem", border: "1px solid #006d38", fontWeight: "bold", display: "flex", alignItems: "center", textTransform: "uppercase" }}>Datos personales sensibles</div>
                  <div style={{ width: "67%", padding: "1rem", border: "1px solid #006d38" }}>
                    <p className="pb-4">
                      Origen de los recursos, monto de sus ingresos, monto de su salario en caso de ser aplicable, número de semanas cotizadas en el Instituto Mexicano del Seguro Social en caso de ser aplicable, historial laboral, historial crediticio, información sobre dependientes económicos,
                      ingresos, clave bancaria, estado de cuenta en caso de ser aplicable.{" "}
                    </p>
                    <p className="pb-4">
                      El Acreditado autoriza al “Responsable” para que (i) lleve a cabo grabaciones de voz derivadas de conversaciones sostenidas entre el Acreditado y los empleados de Difinance, S.A. de C.V., SOFOM, E.N.R., y (ii) recopile, procese, use y transfiera la información personal del
                      Acreditado de conformidad con la LFPDPPP y con el Aviso de Privacidad cuyo propósito es, recopilar, usar y almacenar la información personal, comercial, financiera y de historial crediticio del Acreditado con entidades nacionales e internacionales relacionadas con Difinance,
                      así como con prestadores de servicios, incluyendo pero sin limitar a las agencias de cobranza con la finalidad de cumplir las obligaciones del "Responsable" de conformidad con la LFPDPPP.
                    </p>
                    <p className="pb-4">
                      El Acreditado otorga su consentimiento para que el “Responsable”, de conformidad con el artículo 28 de la Ley para Regular las Sociedades de Información Crediticia, pueda consultar su historial crediticio de forma periódica ante dichas entidades; lo anterior, para efecto de que
                      Difinance pueda llevar un adecuado análisis para el otorgamiento de Crédito.
                    </p>
                    <p className="pb-4">
                      El Acreditado reconoce y acepta que el hecho de solicitar el Crédito a través de las páginas de internet https://www.vivus.com.mx o a través de cualquier otro medio digital que el “Responsable” ponga a su disposición, constituyen la aceptación de los términos del presente
                      contrato, y cualquier acción relacionada con: presionar o hacer “click” a través de cualquier dispositivo electrónico en casillas de verificación denominadas como “checkbox”, el uso de usuarios y contraseñas, códigos dinámicos enviados vía correo electrónico, SMS o a través de
                      alguna aplicación de un dispositivo electrónico móvil o “firmas digitales” a través de dichos dispositivos, harán las veces de “Firma Electrónica Simple” del Acreditado.
                    </p>
                  </div>
                </div>
              </div>
              <p className="pb-4">** Si usted nos proporciona datos personales de terceros, reconoce que tiene consentimiento de estos terceros para que el “Responsable” trate sus datos con sujeción a las mismas medidas de seguridad y Aviso de privacidad que usted.</p>
              <p className="pb-4">Se entenderá como firma digital, a aquella que ejecute el usuario a través de su puño y letra, con signos inequívocos como casillas y/o check box, mediante tabletas electrónicas, teléfonos inteligentes o computadoras.</p>
              <p className="pb-4">En este sentido, le informamos que sus “Datos” serán tratados y resguardados con base en los principios consagrados en la “Ley”, de licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.</p>
              <h2 className="font-bold text-2xl mt-2">3. Finalidades del tratamiento de sus datos personales.</h2>
              <p className="pb-4">
                Los “Datos” que recabamos de Usted, los utilizaremos para las siguientes <strong>finalidades</strong>:
              </p>
              <h3 className="font-bold pb-4">Finalidades Primarias:</h3>
              <ol className="list-decimal ml-4">
                <li className="pb-4">Cumplir con los fines vinculados con la relación jurídica y contractual.</li>
                <li className="pb-4">
                  Cumplimiento normativo, incluyendo integrar los expedientes que exige la normatividad aplicable, así como para prevenir y combatir ilícitos relacionados con nuestros productos y servicios, tales como lavado de dinero, financiamiento al terrorismo, fraude y otros delitos
                  patrimoniales.
                </li>
                <li className="pb-4">Tramitar pagos y facturas.</li>
                <li className="pb-4">
                  Usted autoriza expresamente que <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong> comparta sus datos personales a terceros prestadores de servicios, en los que, dada la naturaleza de los servicios que brindan a <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong>,
                  requieran tener acceso a sus datos personales, de forma enunciativa más no limitativa tales como: agencias o empresas de cobranza extrajudicial; despachos jurídicos de cobranza extrajudicial y judicial; despachos jurídicos para la atención de procedimientos ante la Comisión
                  Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros o ante cualquier autoridad administrativa y judicial; desarrolladores de sistemas o licenciantes, en los cuales, dada la naturaleza de los servicios que prestan, estos deban almacenar sus datos
                  personales en infraestructura, incluyendo aquella conocida como "nube", ya sea propia o de otros terceros.
                </li>
                <li className="pb-4">
                  Asimismo, usted autoriza a <strong>DIFINANCE , S.A. de C.V.</strong>, SOFOM, E.N.R. para que comparta sus datos personales a terceros tales: como cesionarios, fideicomisos, acreedores prendarios, entre otros, con los que, por decisiones de negocio de Difinance, se realicen
                  operaciones de venta de cartera, bursatilizaciones, otorgamientos de garantía a terceros (prenda).
                </li>
              </ol>
              <h3 className="font-bold pb-4">Finalidades Secundarias:</h3>
              <p className="pb-4">De manera adicional y únicamente con su consentimiento, utilizaremos sus “Datos” para las siguientes finalidades secundarias:</p>
              <ol>
                <li>Para fines de mercadotecnia, publicidad, prospección comercial de nuestros productos y servicios, o para invitarlo a eventos institucionales.</li>
              </ol>
              <p className="pb-4">
                De igual forma, en caso de que posteriormente no desee que sus “Datos” sean tratados para estos fines adicionales o secundarios, usted puede revocar su consentimiento en cualquier momento, mediante el envío de un correo electrónico a la siguiente dirección electrónica:{" "}
                <a className="text-green-dark" href="mailto:datospersonales@digitalfinance.com">
                  datospersonales@digitalfinance.com
                </a>
              </p>
              <h2 className="font-bold text-2xl mt-2">4. Medios para limitar el uso o divulgación de sus datos personales.</h2>
              <p className="pb-4">
                Hacemos de su conocimiento que para la protección de sus “Datos” hemos instrumentado medidas de seguridad de carácter administrativo, físico y técnico con el objeto de evitar pérdidas, mal uso o alteración de su información, las cuales han sido implementadas, en términos de la “Ley”,
                con el objeto de proteger sus datos contra cualquier daño, pérdida, alteración, destrucción o el uso, acceso, tratamiento y divulgación no autorizados, así como acotar cualquier riesgo que pudiera llegar a presentarse. Dichos datos serán tratados y resguardados con un alto estándar
                de seguridad, ética y estricta confidencialidad, y únicamente para los fines de la relación jurídica.
              </p>
              <p className="pb-4">
                Asimismo hacemos de su conocimiento que, para limitar el uso o divulgación de sus ”Datos”, en cualquier momento podrá enviar su solicitud a nuestra dirección electrónica{" "}
                <a href="mailto:datospersonales@digitalfinance.com" className="text-green-dark">
                  datospersonales@digitalfinance.com
                </a>
                . En caso de que su solicitud sea procedente, le notificaremos cuáles serán las acciones aplicables a su caso.
              </p>
              <p className="pb-4">De igual forma, usted cuenta con las siguientes opciones:</p>
              <ul>
                <li>Solicitando su inscripción en el Registro Público de Consumidores, también conocido como “Registro Público para Evitar la Publicidad” previsto en la Ley Federal de Protección al Consumidor.</li>
                <li>Solicitando su inscripción en el Registro Público de Usuarios previsto en la Ley de Protección y Defensa al Usuario de Servicios Financieros</li>
              </ul>
              <h2 className="font-bold text-2xl mt-2">5. Medios para ejercer los Derechos de Acceso, Rectificación, Cancelación y Oposición (Derechos ARCO).</h2>
              <p className="pb-4">Usted tiene los siguientes derechos respecto de sus datos personales, identificados como Derechos ARCO:</p>
              <p className="pb-4">
                <strong>Acceso</strong>: Conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones de su uso.
                <br />
                <strong>Rectificación</strong>: Solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta, proporcionando evidencia de la información correcta.
                <br />
                <strong>Cancelación</strong>: Que sus datos sean eliminados de nuestros registros o bases de datos cuando considere que no están siendo usados conforme a la normatividad aplicable.
                <br />
                <strong>Oposición</strong>: Manifestar su oposición al uso de sus datos personales para algún fin específico.
                <br />
              </p>
              <p className="pb-4">
                La forma para ejercer sus derechos de acceso, rectificación, cancelación y oposición (Derechos ARCO), así como limitar el uso o divulgación de sus “Datos” o revocar el consentimiento que nos haya otorgado, será atendido por la Coordinación de Datos Personales del “Responsable”, y a
                través de los siguientes medios:
              </p>
              <ol type="1" className="list-decimal">
                <li>
                  Enviando la solicitud de ejercicio de Derechos ARCO que ponemos a su disposición en nuestro sitio web{" "}
                  <a style={{ color: "#006d38" }} href="https://www.vivus.com.mx">
                    https://www.vivus.com.mx
                  </a>{" "}
                  y{" "}
                  <a style={{ color: "#006d38" }} href="https://www.mivivus.com">
                    https://www.mivivus.com
                  </a>{" "}
                  a nuestro correo electrónico:{" "}
                  <a className="text-green-dark" href="mailto:datospersonales@digitalfinance.com">
                    datospersonales@digitalfinance.com
                  </a>
                </li>
                <li>
                  Por escrito en el formato proporcionado por <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong>, cumpliendo los requisitos de la “Ley”, enviado a nuestro correo electrónico:{" "}
                  <a href="mailto:datospersonales@digitalfinance.com" className="text-green-dark">
                    datospersonales@digitalfinance.com
                  </a>
                  .
                </li>
              </ol>
              <p className="pb-4">En caso de solicitarlo, le daremos la orientación que requiera en esta materia, a través del correo electrónico datospersonales@digitalfinance.com</p>
              <h3 className="font-bold pb-4">¿En qué plazos responderemos su solicitud de derechos ARCO?</h3>
              <ol type="i" className="list-decimal">
                <li>
                  <span style={{ textDecoration: "underline" }}>5 días hábiles</span>, en caso de que necesitemos información adicional sobre su solicitud.
                </li>
                <li>
                  Usted contará con <span style={{ textDecoration: "underline" }}>10 días hábiles</span> para proporcionar la información que le solicitemos adicionalmente; en caso de no proporcionar la misma dentro de ese plazo, se tendrá por no presentada la solicitud correspondiente, en términos
                  de ley.
                </li>
                <li>
                  Contamos con <span style={{ textDecoration: "underline" }}>20 días hábiles</span> para emitirle respuesta. Si existe causa justificada, y para otorgarle una respuesta adecuada, podremos ampliar este plazo por otros{" "}
                  <span style={{ textDecoration: "underline" }}>20 días hábiles</span>, lo cual se le informará.
                </li>
              </ol>
              <h2 className="font-bold text-2xl mt-2">6. ¿Con quién transfiere el “Responsable” sus datos personales y para qué fines?:</h2>
              <p className="pb-4">El “Responsable” realizará las siguientes transferencias durante el tratamiento de sus “Datos”:</p>
              <ol type="i" className="list-[lower-roman]">
                <li>
                  A Empresas del grupo empresarial al cual pertenece el “Responsable”, considerando que las mismas operan bajo las mismas políticas y procesos de protección de datos personales, y con la finalidad de dar cumplimiento a las obligaciones que derivan del contrato de tarjeta de crédito.
                </li>
                <li>
                  A autoridades financieras, judiciales y administrativas, mexicanas y extranjeras, con la finalidad de dar cumplimiento a nuestras obligaciones derivadas de leyes o tratados internacionales, obligaciones tributarias, así como para el cumplimiento de oficios, notificaciones o
                  requerimientos oficiales.
                </li>
                <li>A terceros con los que el “Responsable” tenga un contrato celebrado para cumplir con las obligaciones del contrato.</li>
                <li>
                  Proveedores que resulten necesarios para cumplir con las obligaciones derivadas de su, entre los cuales se encuentran de forma enunciativa más no limitativa, los centros de contacto, cobranza telefónica, plataformas digitales para la administración, facturación, y cobranza de
                  productos, aplicaciones, contenidos y Páginas web relacionadas con los productos.
                </li>
                <li>
                  Agencias o empresas de cobranza extrajudicial; despachos jurídicos de cobranza extrajudicial y judicial; despachos jurídicos para la atención de procedimientos ante la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros o ante cualquier autoridad
                  administrativa y judicial; desarrolladores de sistemas o licenciantes, en los cuales, dada la naturaleza de los servicios que prestan, estos deban almacenar sus datos personales en infraestructura, incluyendo aquella conocida como "nube", ya sea propia o de otros terceros.
                </li>
                <li>
                  Cesionarios, fideicomisos, acreedores prendarios, entre otros, con los que, por decisiones de negocio de <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong>, se realicen operaciones de venta de cartera, bursatilizaciones, otorgamientos de garantía a terceros (prenda).
                </li>
              </ol>
              <p className="pb-4">
                Toda transferencia se realizará solo cuando sea estrictamente indispensable y advirtiendo respecto la confidencialidad de los datos personales y el presente Aviso de Privacidad. El “Responsable” no transferirá sus datos personales a terceros, para finalidades distintas a las
                establecidas en el presente Aviso de Privacidad, sin perjuicio de las excepciones contenidas en la Ley y su Reglamento. El “Responsable” no transferirá sus datos personales a terceros, para finalidades distintas a las establecidas en el presente Aviso de Privacidad, sin perjuicio de
                las excepciones contenidas en la Ley y su Reglamento.
              </p>
              <p className="pb-4">
                En virtud de ello, las anteriores transferencias por su propia naturaleza, no requieren que usted otorgue su consentimiento. El “Responsable” no transferirá ni compartirá sus datos personales con terceros para fines ajenos a los estipulados en el presente Aviso, ni para
                comercialización de productos ajenos a su grupo empresarial. Conforme al principio de proporcionalidad de la información, sólo se transmitirán aquellos datos que sean indispensables para las finalidades antes descritas.
              </p>
              <p className="pb-4">En caso de que sus datos personales deban ser transferidos a algún Sujeto Obligado, como son las autoridades en ejercicio de sus funciones, sus datos estarán protegidos conforme a la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados.</p>

              <h2 className="font-bold text-2xl mt-2">7. Uso de tecnologías de rastreo en el portal de Internet del “Responsable”.</h2>
              <p className="pb-4">Le informamos que en nuestra página web usamos cookies y otras tecnologías de rastreo de su comportamiento como usuario de internet, para una mejor experiencia al navegar en nuestra página.</p>
              <p className="pb-4">
                En materia de Internet, una cookie es una breve información que una página web envía a su computadora, la cual queda almacenada en su disco duro. De este modo, la próxima vez que ingrese a nuestra página, podremos usar la información almacenada en la cookie para facilitarle el uso de
                nuestro sitio de Internet. Una cookie no nos permite conocer su identidad personal a menos que expresamente elija proporcionárnosla.
              </p>
              <p className="pb-4">
                La mayoría de las cookies expiran después de un periodo determinado de tiempo, o bien usted puede borrarlas en el momento en que lo desee de su explorador. Asimismo, puede hacer que su navegador le avise cuando recibe una cookie de manera que pueda aceptarla o rechazarla.
              </p>
              <h2 className="font-bold text-2xl mt-2">8. Modificaciones al aviso de privacidad.</h2>
              <p className="pb-4">
                El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de las propias necesidades del “Responsable” por los servicios que ofrece, de las prácticas de privacidad o por otras causas, por lo que el “Responsable”
                se reserva el derecho de efectuar en cualquier momento las modificaciones o actualizaciones que sean necesarias al presente aviso de privacidad.{" "}
              </p>
              <p className="pb-4">
                El “Responsable” pondrá a disposición del cliente, la versión actualizada del aviso de privacidad, en la página de internet que a continuación se indica:{" "}
                <a style={{ color: "#006d38" }} href="https://www.vivus.com.mx">
                  https://www.vivus.com.mx
                </a>{" "}
                y{" "}
                <a style={{ color: "#006d38" }} href="https://www.mivivus.com">
                  https://www.mivivus.com
                </a>
                .
              </p>
              <p className="pb-4">
                Este Aviso de Privacidad complementa cualesquiera otros avisos de privacidad simplificados o cortos que el “Responsable” haya puesto a su disposición por ser el titular de sus datos personales y resulta supletorio en todo aquello que expresamente no refieran tales avisos.
              </p>
              <p className="pb-4">
                El Acreditado declara que el contenido de dicho Aviso de Privacidad fue puesto a su disposición por el “Responsable” con anterioridad a la fecha de firma del Contrato, por lo que el Acreditado confirma y ratifica, por este medio, que otorgó su consentimiento expreso con la finalidad
                de que el “Responsable” pueda, recopilar, usar, procesar y almacenar su información personal con anterioridad a la celebración del presente Contrato de conformidad con la LFPDPPP. Asimismo, el Acreditado reconoce que el Aviso de Privacidad cumple con lo previsto por el Articulo 16 de
                la LFPDPPP.
              </p>
              <h2 className="font-bold text-2xl mt-2">9. Consentimiento</h2>
              <p className="pb-4">
                En este acto reconozco haber recibido y leído el Aviso de Privacidad para Clientes de <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong> y en mi carácter de titular de los “Datos” proporcionados para los propósitos y bajo las condiciones de confidencialidad y seguridad
                establecidas en el presente Aviso de Privacidad y por la “Ley”.
              </p>
              <p className="pb-4">
                <strong>
                  ** Le informamos que, por su seguridad, y terceros de los que podamos recabar información a través suyo, el uso y tratamiento de la misma será en los mismos términos establecidos en el presente Aviso de Privacidad y de ninguna manera se le dará una protección distinta que la de los
                  datos proporcionados aquí mismo. También es importante que tenga presente que dichos datos no serán transferidos a ningún tercero distinto de los establecidos en el presente documento.
                </strong>
              </p>
              <div style={{ border: "1px solid #006d38", padding: "1rem" }}>
                <p className="pb-4">
                  Sí otorgo a favor de <strong> Difinance, S.A. de C.V., SOFOM, E.N.R.</strong>, mi consentimiento expreso a fin de que se recaben mis datos personales, datos personales sensibles y datos patrimoniales o financieros y sean utilizados para las finalidades antes descritas.
                </p>
                <p className="pb-4">
                  <strong>
                    Por lo que al proporcionar sus datos personales en este sitio web para la cotización o contratación de la tarjeta de crédito. Usted acepta que dichos datos son verídicos y suficientes, y acepta que los mismos sean tratados en términos de este Aviso de Privacidad, para las
                    finalidades primarias y secundarias antes descritas.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
