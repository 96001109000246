import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./routes/Routes";
import NavBar from "./components/common/NavBar";
import { Footer } from "./components/common/Footer";
import { Social } from "./components/common/Social";
import { Route, Switch, withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { AvisoDePrivacidad } from "./components/common/footer-links/AvisoDePrivacidad";
import TerminosCondiciones from "./components/common/footer-links/TerminosCondiciones";
import { FAQ } from "./components/works/FAQ";
import GeneralContract from "./components/common/GeneralContract";
import TiktokPixel from "tiktok-pixel";
import { Helmet } from "react-helmet";
import GeneralPopup from "./components/common/GeneralPopup";
import Calculator from "./components/calculator/Calculator";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { refreshIp } from "./services/ip";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8_8plMFDqYFcyKRYjQ1wmphGXvOdkHEA",
  authDomain: "vivus-app.firebaseapp.com",
  projectId: "vivus-app",
  storageBucket: "vivus-app.appspot.com",
  messagingSenderId: "34357899240",
  appId: "1:34357899240:web:caeb5399f1c3a6b30dfe5d",
  measurementId: "G-YG10SJJFP0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER,
  dataLayerName: "dataLayer",
};

TagManager.initialize(tagManagerArgs);
TiktokPixel.init("CGCT1KJC77U6LAATJ3O0");

const jsonLdData = {
  "@context": "https://schema.org",
  "@type": "LoanOrCredit",
  name: "vivus",
  url: "https://www.vivus.com.mx/",
  logo: "https://www.vivus.com.mx/img/navbar/logo-vivus-mexico.svg",
  description: "Préstamos personales rápidos y fáciles en línea. Obtén hasta MXN/12000 pesos en solo 30 minutos sin papeleo ni burocracia.",
  telephone: "(0155) 6717 0750",
  email: "info@vivus.com.mx",
  address: {
    "@type": "PostalAddress",
    addressLocality: "Df Mexico",
    addressRegion: "Mexico",
    addressCountry: "MX",
  },
  sameAs: ["https://www.facebook.com/VivusMexico/", "https://www.tiktok.com/@vivusmexico", "https://www.instagram.com/vivusmx/"],
  priceRange: "MXN/300 - MXN/12000",
  paymentAccepted: ["Cash", "Credit Card", "Debit Card"],
};

function App(props) {
  const [location, setLocation] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  const [loaded] = useState(false);
  const { pushInstruction } = useMatomo();

  useEffect(() => {
    if (window.location.pathname === "/") setLocation("home");
    if (props.location.pathname.includes("/dashboard")) setLocation("dashboard");
    if (props.location.pathname.includes("/login")) setLocation("app");
    // if(props.location.pathname.includes('/blog')) setLocation('blog')
    if (props.location.pathname.includes("/registration")) setLocation("app");
    if (props.location.pathname.includes("/denied")) setLocation("denied");
    if (props.location.pathname.includes("/contenido")) setLocation("contenido");
    if (props.location.pathname.includes("/us")) setLocation("us");
    if (props.location.pathname.includes("/denied")) setLocation("denied");
    if (props.location.pathname.includes("/en-mantenimiento")) setLocation("manteinance");
    if (sessionStorage.getItem("loggedUser")) setIsLogged(true);
    else setIsLogged(false);
  }, [props.location]);

  useEffect(() => {
    if (sessionStorage.loggedUser) {
      const { customerId } = JSON.parse(sessionStorage.loggedUser);
      pushInstruction("setUserId", customerId + "");
    }
  }, [window?.location?.pathname]);

  useEffect(() => {
    try {
      if (sessionStorage.balance) {
        const { idCredit } = JSON.parse(sessionStorage.balance);
        pushInstruction("setCustomVariable", 1, "LOAN_ID", idCredit, "visit");
      }
    } catch (error) {
      console.log(error);
    }
  }, [window?.location?.pathname]);

  useEffect(() => {
    TiktokPixel.pageView();
  }, [window?.location?.pathname]);

  // Detect network changes to update the IP address
  useEffect(() => {
    if ("connection" in navigator) {
      let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      connection.addEventListener("change", () => refreshIp());
    } else {
      console.log("La API de información de red no es soportada por este navegador.");
      window.addEventListener("online", () => refreshIp());
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="Los préstamos personales en Vivus México son fáciles y rápidos. Obtén tus préstamos en línea en 24 horas. Descubre cómo funciona aquí." />
        <link rel="alternate" href={process.env.REACT_APP_SITE === "mivivus" ? "https://www.mivivus.com/" : "https://www.vivus.com.mx/"} hreflang="es-mx" />
        <link rel="canonical" href={process.env.REACT_APP_SITE === "mivivus" ? "https://www.mivivus.com/" : "https://www.vivus.com.mx/"} />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      <Switch>
        {/* RUTAS EN mantenimiento */}
        {/* <Route exact path='/' component={Home}/> */}
        <Route exact path="/mobile/privacidad" component={AvisoDePrivacidad} />
        <Route exact path="/mobile/terminos-y-condiciones" component={TerminosCondiciones} />
        <Route exact path="/mobile/faq" component={FAQ} />
        <Route exact path="/mobile/contract" component={GeneralContract} />

        <Route exact path="/contenido/calculadora" component={Calculator} />
        <Route
          path="/*"
          render={(props) => (
            <div className="App flex flex-col">
              {loaded ? (
                <div></div>
              ) : (
                <>
                  <>
                    <NavBar isLogged={isLogged} location={location} setLocation={setLocation} />
                    <Routes />
                    <Social location={location} />
                  </>
                  <Footer location={location} />
                  <GeneralPopup />
                </>
              )}
            </div>
          )}
        />
      </Switch>
    </>
  );
}

export default withRouter(App);
