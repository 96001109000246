import { useState } from "react";
import { AccordionFaq } from "../../works/AccordionFaq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";

const questions = [
  {
    question: "¿Qué son los préstamos online?",
    answer: (
      <div className="flex flex-col gap-4 mt-4">
        <p>
          Los préstamos en línea se han convertido en una alternativa cada vez más popular para obtener dinero rápido y sin complicaciones. Con nuestros préstamos en línea, puedes solicitar y recibir el dinero que necesitas de forma ágil y conveniente. Con nosotros se terminaron para ti los trámites
          engorrosos o las largas esperas. Nuestro proceso de solicitud en línea es simple y transparente, lo que significa que puedes completarlo desde la comodidad de tu hogar, en cualquier momento del día.
        </p>
        <p>En tu primer préstamo puedes obtener hasta $3,000, y en el segundo puedes solicitar hasta $12,000 si lo deseas. Puedes liquidar tu préstamo en el término de hasta 90 días. En Vivus, te brindamos la solución a tus urgencias y a tus sueños pospuestos.</p>
        <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
          ¡SOLICÍTALO AQUÍ!
        </a>
      </div>
    ),
  },
  {
    question: "Préstamos en línea rápidos y seguros",
    answer: (
      <div className="flex flex-col gap-4 mt-4">
        <p>En Vivus, entendemos la importancia de contar con préstamos en línea rápidos y confiables cuando necesitas dinero de forma urgente. Nuestros servicios te brindan la tranquilidad de obtener el financiamiento que requieres justo cuando más lo necesitas.</p>
        <p>Nuestro proceso de solicitud en línea es sencillo y eficiente:</p>

        <div className="flex flex-col gap-4">
          <div className="flex gap-2">
            <FontAwesomeIcon icon={faCheck} className="text-green-dark text-xl" />
            <div className="flex flex-col">
              <span className="font-bold">Sin trámites presenciales </span>
              <span>Olvídate de tener que visitar una oficina.</span>
            </div>
          </div>

          <div className="flex gap-2">
            <FontAwesomeIcon icon={faCheck} className="text-green-dark text-xl" />
            <div className="flex flex-col">
              <span className="font-bold">Documentación mínima </span>
              <span>Solo necesitarás tu identificación oficial (IFE/INE) y una cuenta bancaria.</span>
            </div>
          </div>
          <div className="flex gap-2">
            <FontAwesomeIcon icon={faCheck} className="text-green-dark text-xl" />
            <div className="flex flex-col">
              <span className="font-bold">Aprobación rápida </span>
              <span>Aprobación rápida Una vez que tu préstamo sea aprobado, nuestro equipo te contactará en breve para recibir tu dinero. Además de ser rápidos, nuestros préstamos son seguros.</span>
            </div>
          </div>
        </div>

        <p>En Vivus, nos tomamos muy en serio la protección de tus datos y la fiabilidad de las transacciones. Utilizamos tecnología de encriptación de última generación para garantizar la confidencialidad y protección de tu información personal.</p>
        <p>Nuestros altos estándares de seguridad y transparencia nos han convertido en una opción confiable para miles de clientes. Contamos con la regulación y supervisión DE CONDUSEF, lo que brinda tranquilidad y respaldo a nuestros solicitantes.</p>
        <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
          ¡SOLICÍTALO AQUÍ!
        </a>
      </div>
    ),
  },
];

const Question = ({ question, children, open, onToggle }) => {
  return (
    <div className={`flex w-full flex-col rounded-xl p-4 lg:p-8 ${open ? "bg-white" : "bg-green-light"}`}>
      <div className="flex justify-between items-center cursor-pointer" onClick={onToggle}>
        <div className={`text-2xl font-bold transition-all ${open ? "text-green-light" : "text-white"}`}>{question}</div>
        <div className={`text-lg my-0 mr-4 ${open ? "text-gray-text" : "text-white"}`}>{open ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
      </div>
      {open ? <div className={`flex flex-col gap-4 ${open ? "text-gray-600" : "text-white"}`}>{children}</div> : null}
    </div>
  );
};

const OnlineLoans = () => {
  const [openItem, setOpenItem] = useState(0);

  return (
    <div className="flex justify-center lg:justify-center lg:px-24 2xl:px-64 text-white bg-green-light">
      <div className="flex container px-8 flex-col md:flex-row md:items-center justify-center py-12 lg:py-24 w-full">
        <div className="flex flex-col gap-4 w-full lg:w-4/6">
          <h2 className="text-3xl lg:text-4xl font-bold mb-4 text-start">Préstamos en Línea Confiables: Obtén Dinero Rápido y Seguro</h2>{" "}
          <p className="mb-6 text-xl">¡Bienvenido a Vivus, tu financiera de préstamos en línea confiables! Si estás buscando préstamos en línea rápidos y seguros, estás en el lugar indicado. Nos especializamos en brindarte soluciones financieras de forma ágil y confiable.</p>
          <div className="flex flex-col gap-2">
            {questions.map((question, index) => (
              <Question
                open={openItem == index}
                question={question.question}
                onToggle={() => {
                  setOpenItem(index == openItem ? -1 : index);
                }}
              >
                {question.answer}
              </Question>
            ))}
          </div>
        </div>
        <div className="hidden lg:w-2/6 lg:flex items-center justify-center">
          <img src="/img/landing/Solicitud-en-línea.png" alt="Vivus: Préstamos rápidos y seguros al alcance de tu mano." />
        </div>
      </div>
    </div>
  );
};

export default OnlineLoans;
