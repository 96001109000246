import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Calculator from "../../calculator/Calculator";
import "../../../index.css";
import "../../../App.css";

const Cover = () => {
  const [monthName, setMonthName] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const date = new Date();
    switch (date.getMonth()) {
      case 0:
        setMonthName("january");
        break;
      case 1:
        setMonthName("february");
        break;
      case 2:
        setMonthName("march");
        break;
      case 3:
        setMonthName("april");
        break;
      case 4:
        setMonthName("may");
        break;
      case 5:
        setMonthName("june");
        break;
      case 6:
        setMonthName("july");
        break;
      case 7:
        setMonthName("august");
        break;
      case 8:
        setMonthName("september");
        break;
      case 9:
        setMonthName("october");
        break;
      case 10:
        setMonthName("november");
        break;
      case 11:
        setMonthName("december");
        break;
      default:
        setMonthName(null);
        break;
    }
  }, []);

  useEffect(() => {
    switch (monthName) {
      case "january":
        setBackgroundImage("/img/landing/landing_JANUARY.jpg");
        break;
      case "february":
        setBackgroundImage("/img/landing/landing_FEBRUARY.jpg");
        break;
      case "march":
        setBackgroundImage("/img/landing/landing_MARCH.jpg");
        break;
      case "april":
        // Check for the size of the screen (three sizes based on width: mobile < 768px, tablet/desktop < 1024px, large > 1600px)
        if (windowWidth < 768) {
          setBackgroundImage("/img/landing/landing_APRIL_SM.jpg");
        } else if (windowWidth < 1024) {
          setBackgroundImage("/img/landing/landing_APRIL_MD.jpg");
        } else {
          setBackgroundImage("/img/landing/landing_APRIL.jpg");
        }
        break;
      case "may":
        // Check for the size of the screen (three sizes based on width: mobile < 768px, tablet/desktop < 1024px, large > 1600px)
        if (windowWidth < 768) {
          setBackgroundImage("/img/landing/landing_MAY_SM.jpg");
        } else if (windowWidth < 1024) {
          setBackgroundImage("/img/landing/landing_MAY_MD.jpg");
        } else {
          setBackgroundImage("/img/landing/landing_MAY.jpg");
        }
        break;
      case "june":
        setBackgroundImage("/img/landing/landing_JUNE.jpg");
        break;
      case "july":
      case "august":
        // Check for the size of the screen (three sizes based on width: mobile < 768px, tablet/desktop < 1024px, large > 1600px)
        if (windowWidth < 768) {
          setBackgroundImage("/img/landing/landing_AUG_SM.jpg");
        } else if (windowWidth < 1024) {
          setBackgroundImage("/img/landing/landing_AUG_MD.jpg");
        } else {
          setBackgroundImage("/img/landing/landing_AUG.jpg");
        }
        break;
      case "september":
        setBackgroundImage("/img/landing/landing_SEPTEMBER.jpg");
        break;
      case "october":
        if (windowWidth < 768) {
          setBackgroundImage("/img/landing/landing_OCT_MD.jpg");
        } else if (windowWidth < 1024) {
          setBackgroundImage("/img/landing/landing_OCT_MD.jpg");
        } else {
          setBackgroundImage("/img/landing/landing_OCT.jpg");
        }
        break;
      case "november":
        if (windowWidth < 768) {
          setBackgroundImage("/img/landing/landing_NOV_SM.jpg");
        } else if (windowWidth < 1024) {
          setBackgroundImage("/img/landing/landing_NOV_MD.jpg");
        } else {
          setBackgroundImage("/img/landing/landing_NOV.jpg");
        }
        break;
      case "december":
        if (windowWidth < 768) {
          setBackgroundImage("/img/landing/landing_DECEMBER_SM.jpg");
        } else if (windowWidth < 1024) {
          setBackgroundImage("/img/landing/landing_DECEMBER_MD.jpg");
        } else {
          setBackgroundImage("/img/landing/landing_DECEMBER.jpg");
        }
        break;
      default:
        setBackgroundImage("/img/landing/landing.jpg");
        break;
    }
  }, [monthName, windowWidth]);

  return (
    <section className="w-full flex flex-col items-center lg:flex-row lg:items-start justify-center relative lg:bg-cover lg:bg-center xl:pb-12 lg:min-h-lg cover xl:min-h-cover">
      {/* <img src={"/img/landing/sticker.png"} alt="préstamo garantizado" width={280} className="absolute bottom-20 hidden translate-x-36 z-20 2xl:block" /> */}
      {/* {monthName === "december" ? (
        <>
          <img src={"/img/landing/person_DECEMBER.webp"} alt="logo" width={480} className="absolute bottom-0 hidden translate-x-28 z-20 2xl:block" />
          <img src={"/img/landing/person_DECEMBER.webp"} alt="logo" width={430} className="absolute bottom-0 hidden translate-x-24 z-20 xl:block 2xl:hidden" />
        </>
      ) : null}

      {monthName === "january" ? <img src="/img/landing/cuesta_JANUARY.png" alt="Cuesta con Vivus" width={400} className="absolute 2xl:block translate-x-16 top-20 hidden" /> : null}

      {monthName !== "december" ? (
        <>
          <img src={"/img/landing/person.webp"} alt="logo" width={390} className="absolute bottom-0 hidden translate-x-28 z-20 2xl:block" />
          <img src={"/img/landing/person.webp"} alt="logo" width={370} className="absolute bottom-0 hidden translate-x-24 z-20 xl:block 2xl:hidden" />
        </>
      ) : null} */}

      {/* <img src="/img/landing/person.webp" alt="logo" width={300} className="absolute bottom-0 hidden translate-x-28 lg:block xl:hidden left-8" /> */}
      <div className="absolute w-full pointer-events-none h-full block -z-10 bg-cover bg-top top-0" style={{ backgroundImage: `url(${backgroundImage})` }} />
      <div className="container self-center flex flex-col lg:flex-row py-4 gap-8 justify-center lg:justify-between lg:items-center xl:items-center px-4 -pt-4 z-10 lg:py-8">
        <div className="flex flex-col-reverse lg:flex-col max-w-3xl gap-4">
          {/* <div className="p-4 bg-white bg-opacity-90 rounded-xl mt-4 flex items-center lg:hidden gap-4 w-full max-w-3xl justify-between">
            <span className="font-bold w-3/4 ">Los servicios Difinance, S.A. de C.V., SOFOM, E.N.R. (“DIFINANCE”) son debidamente regulados por CONDUSEF</span>
            <Link to="/informacion-regulatoria" className="text-green-dark underline font-bold w-1/4 text-end">
              Ver más
            </Link>
          </div> */}
          <h1 className="text-3xl lg:text-4xl xl:text-6xl font-bold text-green-dark lg:mt-0 hidden md:flex">Préstamos Personales en línea, fácil aprobación</h1>
          <h1 className="text-3xl lg:text-4xl xl:text-6xl font-bold text-green-dark lg:mt-0 md:hidden flex">Préstamos 100% en línea</h1>
          {monthName !== "june" && monthName !== "july" && (
            <>
              <h2 class="text-2xl text-gray-text lg:hidden">Obtén hasta $3,000 pesos en tu primer crédito.</h2>
              <ul className="hidden lg:block">
                <li className="text-xl lg:text-2xl text-gray-text flex gap-4 items-center my-2">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>
                    Obtén hasta <strong className="font-bold text-green-light"> $3,000</strong> en tu primer crédito.
                  </span>
                </li>
                <li className="text-xl lg:text-2xl text-gray-text flex gap-4 items-center my-2">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>
                    Todo el proceso es <strong className="font-bold text-green-light"> en línea </strong> y sin papeleo.
                  </span>
                </li>
                <li className="text-xl lg:text-2xl text-gray-text flex gap-4 items-center my-2">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Con total transparencia y seguridad.</span>
                </li>
                <li className="text-xl lg:text-2xl text-gray-text flex gap-4 items-center my-2 max-w-xl">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>
                    Si ya eres cliente Vivus, puedes solicitar hasta <strong className="font-bold text-green-light">$12,000</strong>,{" "}
                    <Link to="/login" className="underline font-bold text-green-dark">
                      accediendo a tu perfil
                    </Link>
                    .
                  </span>
                </li>
              </ul>
            </>
          )}

          {monthName === "june" && (
            <div className="w-full h-full py-4 lg:py-12 flex items-center justify-center ">
              <img src={"img/landing/june-cover-image.png"} alt="Día del padre" className="w-full max-w-[400px] lg:max-w-full" />
            </div>
          )}
          {/* {monthName === "july" && (
            <div className="w-full h-full py-4 lg:py-12 flex items-center justify-center ">
              <img src={"img/landing/july-cover-image.png"} alt="Vive el verano" className="w-full max-w-[400px] lg:max-w-[600px]" />
            </div>
          )} */}

          {(monthName === "july" || monthName === "august") && (
            <div className="flex flex-col gap-4">
              <div className="w-full h-full py-4 lg:py-12 flex items-center justify-center ">
                <img src={"img/landing/august-cover-image.png"} alt="Vive el verano" className="w-full max-w-[400px] lg:max-w-[600px]" />
              </div>
              <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-md flex items-center justify-center text-center self-center justify-self-center text-xl" href="https://play.google.com/store/apps/details?id=mx.vivus">
                ¡DESCÁRGALA AHORA Y DISFRUTA!
              </a>
            </div>
          )}
        </div>
        <Calculator home={true} />
      </div>
    </section>
  );
};

export default Cover;
